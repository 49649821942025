var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

(function () {
  L.Control.FullScreen = L.Control.extend({
    options: {
      position: "topleft",
      title: "Full Screen",
      titleCancel: "Exit Full Screen",
      forceSeparateButton: false,
      forcePseudoFullscreen: false,
      fullscreenElement: false
    },
    onAdd: function (map) {
      var className = "leaflet-control-zoom-fullscreen",
          container,
          content = "";

      if (map.zoomControl && !(this || _global).options.forceSeparateButton) {
        container = map.zoomControl._container;
      } else {
        container = L.DomUtil.create("div", "leaflet-bar");
      }

      if ((this || _global).options.content) {
        content = (this || _global).options.content;
      } else {
        className += " fullscreen-icon";
      }

      this._createButton((this || _global).options.title, className, content, container, (this || _global).toggleFullScreen, this || _global);

      (this || _global)._map.on("enterFullscreen exitFullscreen", (this || _global)._toggleTitle, this || _global);

      return container;
    },
    _createButton: function (title, className, content, container, fn, context) {
      (this || _global).link = L.DomUtil.create("a", className, container);
      (this || _global).link.href = "#";
      (this || _global).link.title = title;
      (this || _global).link.innerHTML = content;
      L.DomEvent.addListener((this || _global).link, "click", L.DomEvent.stopPropagation).addListener((this || _global).link, "click", L.DomEvent.preventDefault).addListener((this || _global).link, "click", fn, context);
      L.DomEvent.addListener(container, fullScreenApi.fullScreenEventName, L.DomEvent.stopPropagation).addListener(container, fullScreenApi.fullScreenEventName, L.DomEvent.preventDefault).addListener(container, fullScreenApi.fullScreenEventName, (this || _global)._handleEscKey, context);
      L.DomEvent.addListener(document, fullScreenApi.fullScreenEventName, L.DomEvent.stopPropagation).addListener(document, fullScreenApi.fullScreenEventName, L.DomEvent.preventDefault).addListener(document, fullScreenApi.fullScreenEventName, (this || _global)._handleEscKey, context);
      return (this || _global).link;
    },
    toggleFullScreen: function () {
      var map = (this || _global)._map;
      map._exitFired = false;

      if (map._isFullscreen) {
        if (fullScreenApi.supportsFullScreen && !(this || _global).options.forcePseudoFullscreen) {
          fullScreenApi.cancelFullScreen((this || _global).options.fullscreenElement ? (this || _global).options.fullscreenElement : map._container);
        } else {
          L.DomUtil.removeClass(map._container, "leaflet-pseudo-fullscreen");
        }

        map.invalidateSize();
        map.fire("exitFullscreen");
        map._exitFired = true;
        map._isFullscreen = false;
      } else {
        if (fullScreenApi.supportsFullScreen && !(this || _global).options.forcePseudoFullscreen) {
          fullScreenApi.requestFullScreen((this || _global).options.fullscreenElement ? (this || _global).options.fullscreenElement : map._container);
        } else {
          L.DomUtil.addClass(map._container, "leaflet-pseudo-fullscreen");
        }

        map.invalidateSize();
        map.fire("enterFullscreen");
        map._isFullscreen = true;
      }
    },
    _toggleTitle: function () {
      (this || _global).link.title = (this || _global)._map._isFullscreen ? (this || _global).options.title : (this || _global).options.titleCancel;
    },
    _handleEscKey: function () {
      var map = (this || _global)._map;

      if (!fullScreenApi.isFullScreen(map) && !map._exitFired) {
        map.fire("exitFullscreen");
        map._exitFired = true;
        map._isFullscreen = false;
      }
    }
  });
  L.Map.addInitHook(function () {
    if ((this || _global).options.fullscreenControl) {
      (this || _global).fullscreenControl = L.control.fullscreen((this || _global).options.fullscreenControlOptions);
      this.addControl((this || _global).fullscreenControl);
    }
  });

  L.control.fullscreen = function (options) {
    return new L.Control.FullScreen(options);
  };
  /* 
  Native FullScreen JavaScript API
  -------------
  Assumes Mozilla naming conventions instead of W3C for now
  
  source : http://johndyer.name/native-fullscreen-javascript-api-plus-jquery-plugin/
  
  */


  var fullScreenApi = {
    supportsFullScreen: false,
    isFullScreen: function () {
      return false;
    },
    requestFullScreen: function () {},
    cancelFullScreen: function () {},
    fullScreenEventName: "",
    prefix: ""
  },
      browserPrefixes = "webkit moz o ms khtml".split(" "); // check for native support

  if (typeof document.exitFullscreen !== "undefined") {
    fullScreenApi.supportsFullScreen = true;
  } else {
    // check for fullscreen support by vendor prefix
    for (var i = 0, il = browserPrefixes.length; i < il; i++) {
      fullScreenApi.prefix = browserPrefixes[i];

      if (typeof document[fullScreenApi.prefix + "CancelFullScreen"] !== "undefined") {
        fullScreenApi.supportsFullScreen = true;
        break;
      }
    }

    if (typeof document["msExitFullscreen"] !== "undefined") {
      fullScreenApi.prefix = "ms";
      fullScreenApi.supportsFullScreen = true;
    }
  } // update methods to do something useful


  if (fullScreenApi.supportsFullScreen) {
    if (fullScreenApi.prefix === "ms") {
      fullScreenApi.fullScreenEventName = "MSFullscreenChange";
    } else {
      fullScreenApi.fullScreenEventName = fullScreenApi.prefix + "fullscreenchange";
    }

    fullScreenApi.isFullScreen = function () {
      switch ((this || _global).prefix) {
        case "":
          return document.fullscreen;

        case "webkit":
          return document.webkitIsFullScreen;

        case "ms":
          return document.msFullscreenElement;

        default:
          return document[(this || _global).prefix + "FullScreen"];
      }
    };

    fullScreenApi.requestFullScreen = function (el) {
      switch ((this || _global).prefix) {
        case "":
          return el.requestFullscreen();

        case "ms":
          return el.msRequestFullscreen();

        default:
          return el[(this || _global).prefix + "RequestFullScreen"]();
      }
    };

    fullScreenApi.cancelFullScreen = function () {
      switch ((this || _global).prefix) {
        case "":
          return document.exitFullscreen();

        case "ms":
          return document.msExitFullscreen();

        default:
          return document[(this || _global).prefix + "CancelFullScreen"]();
      }
    };
  } // jQuery plugin


  if (typeof jQuery !== "undefined") {
    jQuery.fn.requestFullScreen = function () {
      return this.each(function () {
        var el = jQuery(this || _global);

        if (fullScreenApi.supportsFullScreen) {
          fullScreenApi.requestFullScreen(el);
        }
      });
    };
  } // export api


  window.fullScreenApi = fullScreenApi;
})();

export default {};